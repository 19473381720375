<template lang="html">
  <div class="listaConsultas">
    <br>
    <button icon-pack="feather" icon="icon-printer" class="float-right btn btn-primary" style="margin-right:5%;margin-bottom:2%;"@click="print">Imprimir</button>
    <br>
    <div class="personales">
          <p><b>Personas: {{solicitante.Nombres}} {{solicitante.ApellidoPaterno}} {{solicitante.ApellidoMaterno}}</b></p>
          <p>Domicilio: {{solicitante.Calle}} {{solicitante.NumeroExterior}}, {{solicitante.Colonia}}, {{solicitante.Municipio}} CP.{{solicitante.CodigoPostal}}</p>
          <p>Fecha de nacimiento: {{solicitante.FechaNacimiento}}</p>
          <p>RFC: {{solicitante.Rfc}}</p>
          <p class="fico"><b>Calificación FICO {{consultaCirculo.strScore}}</b></p>
          <ul>
            <p><li>De 300 a 550. Malo</li></p>
            <p><li>De 551 a 650. Regular</li></p>
            <p><li>De 651 a 750. Bueno</li></p>
            <p><li>De 751 a 850. Excelente</li></p>
          </ul>
        </div>
    <vs-table :data="creditos" max-items="100"
      pagination
      class="tabla">
      <template slot="header">
      </template>
      <template slot="thead">
        <vs-th style="font-size:10px;">
          Información
        </vs-th>
        <vs-th style="font-size:10px;">
          Fecha apertura
        </vs-th>
        <vs-th style="font-size:10px;">
          Fecha actualización
        </vs-th>
        <vs-th style="font-size:10px;">
          Monto a pagar
        </vs-th>
        <vs-th style="font-size:10px;">
          Limite credito
        </vs-th>
        <vs-th style="font-size:10px;">
          Concepto pago
        </vs-th>
        <vs-th style="font-size:10px;">
          Saldo actual
        </vs-th>
        <vs-th style="font-size:10px;">
          Comportamiento
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].Otorgante" style="font-size:10px;">
            {{data[indextr].Otorgante}}, {{data[indextr].Cuenta}}, {{data[indextr].Tipo}}
          </vs-td>

          <vs-td :data="data[indextr].Apertura" style="font-size:10px;">
            {{data[indextr].Apertura}}
          </vs-td>

          <vs-td :data="data[indextr].FechaActualizacion" style="font-size:10px;">
            {{data[indextr].FechaActualizacion}}
          </vs-td>

          <vs-td :data="data[indextr].MontoAPagar" style="font-size:10px;">
            ${{data[indextr].MontoAPagar}}
          </vs-td>
          <vs-td :data="data[indextr].LimiteDeCredito" style="font-size:10px;">
            ${{data[indextr].LimiteDeCredito}}
          </vs-td>
          <vs-td :data="data[indextr].ConceptoDePago" style="font-size:10px;">
            {{data[indextr].ConceptoDePago}}
          </vs-td>
          <vs-td :data="data[indextr].SaldoActual" style="font-size:10px;">
            ${{data[indextr].SaldoActual}}
          </vs-td>
          <vs-td :data="data[indextr].Comportamiento" v-html="data[indextr].Comportamiento" class="comportamiento">
            {{data[indextr].Comportamiento}}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>

</template>

<script>
export default {
  data(){
    return{
      email:'',
      creditos:[],
      consultaCirculo:{},
      solicitante:{},
      SocGuid: '',
      origenConsulta: ''
    }
  },
  mounted(){
    this.email=this.$route.params.id
    this.getApikey()
    this.dameSolicitante()
    this.consultar()

  },
  methods:{
    emailClienteBuro() {

      const objEmail= {
        to: this.email,
				strApiKey: this.key,
      }

      this.$vs.notify({
        title: 'Enviando correo electrónico a',
        text: this.email,
        position:'top-right'
      })

      this.$axios.post('https://cotizador.socasesores.com/emailComparaleProd/EmailClienteBuro.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
				.then(resp => {

					if ( resp.data.status ) {

						return this.$vs.notify({
							title: 'Ocurrio un error en',
							text: 'envio de correo',
							color: 'danger',
							position: 'top-right',
						})

					}

					this.$vs.notify({
						title: 'Se envió correctamente el correo electrónico a',
						text: this.email,
						position:'top-right'
					})

				})
				.catch(function (error) {
					console.log(error);
				})
    },

    async getEstados() {

      try {
        const objRequestListaCasoEstatus = {
          strApiKey: this.key,
          strMetodo: 'ListaEstados',
        }

        const { data } = await this.$axios.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        const { intEstatus, objContenido } = data

        if ( intEstatus ) {
          this.$vs.notify({
            title: 'Ocurrio un error en ListaEstados',
            text: response.data.strError,
            color: 'danger',
            position: 'top-right',
          })

          return []
        }

        return objContenido
        
      } catch (error) {
        self.$vs.notify({
          title: 'Ocurrio un error de sistema',
          text: error,
          color: 'danger',
          position: 'top-right',
        })

        return []
      }
		},

    async SaveFolioSisec ({ Folio, idConsulta }) {

      try {

        const objRequestSaveFolio = {
          strApiKey: this.key,
          strMetodo: 'SaveFolioConsultaSisec',
          objSave: {
            Folio,
            idConsulta
          }
        }

        const { intEstatus, objContenido } = await this.$axios.post('/', objRequestSaveFolio, {headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})

        if ( intEstatus ) {

          this.$vs.notify({
            title:'Ocurrio un error en registro de Folio SISEC',
            text:Message,
            color:'danger',
            position:'top-right'
          })

          return false

        }

        this.$vs.notify({
          title:'Registro exitoso de Folio SISEC',
          text:`Se registro el folio ${Folio}`,
          position:'top-right'
        })

        return objContenido

      } catch (error) {
        console.error(error)

        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }

    },

    async getTokenSisec () {
      try {
        const objRequest = {
          username: 'user',
          password: 'user',
          grant_type: 'password'
        }

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/token'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/token'

        let formBody = [];
        for (const property in objRequest) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(objRequest[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        const data = await this.$axios.post(URL_API_SISEC, formBody,{headers : {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}})

        return data

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }
    },

    async consultarSISEC({ isReusedScore, objCirculo, idConsulta }) {
      try {
        const dataSolicitante = this.solicitante

        const Gender = dataSolicitante.Sexo === 1 ? 'M' : 'F'
        const Nacionalidad = dataSolicitante.Nacionalidad
        const objNacionalidad = {
          'MEXICANO (A)': 'MEXICANA',
          'NORTEAMERICANO (A)': 'NORTEAMERICANA'
        }
        const strNacionalidad = objNacionalidad[Nacionalidad] ? objNacionalidad[Nacionalidad] : Nacionalidad

        // return console.log('strNacionalidad :>> ', strNacionalidad)

        const IsForeing = Nacionalidad === 'MEXICANO (A)' ? false : true
        const IndoorNumber = !dataSolicitante.NumeroInterior ? '0' : dataSolicitante.NumeroInterior

        const listEstados = await this.getEstados()
        const arrState = listEstados.find(x => x.Id === dataSolicitante.Estado)
        const State = arrState.Nombre

        const objConsultaSisec = { 
          BrokerEmployeeId: this.SocGuid,
          Score: JSON.stringify(objCirculo),
          ExternalFolio: idConsulta,
          FirstName: dataSolicitante.Nombres,
          SecondName: null,
          LastName: dataSolicitante.ApellidoPaterno,
          SecondLastName: dataSolicitante.ApellidoMaterno,
          SocialReason: null,
          ConstitutionDate: null,
          Gender,
          IsForeing,
          Nationality: strNacionalidad,
          Email: dataSolicitante.EMail,
          DateOfBirth: `${dataSolicitante.FechaNacimiento} 00:00:00.000`,
          CURP: dataSolicitante.Curp,
          RFC: dataSolicitante.Rfc,
          Phone: dataSolicitante.TelefonoCelular,
          PersonType: 'Fisico',
          Street: dataSolicitante.Calle,
          OutdoorNumber: dataSolicitante.NumeroExterior,
          IndoorNumber,
          Colony: dataSolicitante.Colonia,
          CP: dataSolicitante.CodigoPostal,
          Municipality: dataSolicitante.Municipio,
          City: dataSolicitante.Ciudad,
          State,
          IsEmployee: false,
          TermsAndConditions: true,
          Origin: '2',
          IsReusedScore: isReusedScore

        }

        const TOKEN_SISEC = await this.getTokenSisec()
        const { access_token } = TOKEN_SISEC.data

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Consulta'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Consulta' // PROD

        const resp = await this.$axios.post(URL_API_SISEC, objConsultaSisec, { 
          headers : {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })
        const { status, data } = resp

        // console.log({ SISEC: data })

        if ( status !== 200 ) {
          return this.$vs.notify({
            title:'Ocurrio un error en registro de consulta SISEC',
            text:'Ocurrió un problema, inténtelo nuevamente más tarde.',
            color:'danger',
            position:'top-right'
          })
        }

        const { Folio, Success, Message } = data

        if ( !Success ) {
          return this.$vs.notify({
            title:'Ocurrio un error en registro de consulta SISEC',
            text:Message,
            color:'danger',
            position:'top-right'
          })
        }

        const saveFolioSisec = await this.SaveFolioSisec({ Folio, idConsulta })

        if ( !saveFolioSisec ) return

        return this.$vs.notify({
          title:'Registro exitoso en SISEC',
          text:`Se registro el folio ${Folio}`,
          position:'top-right'
        })
      } catch (error) {

        console.error(error)

        return this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }
    },

    async getOrigenConsultaBuro () {
    
      try {

        // this.$vs.loading({
        //   container: '#casos',
        //   scale: 0.6,
        // })

        const consultasComparador = await this.getConsultasComparador()
        const { intEstatus, objContenido, strError } = consultasComparador
        const consultasSISEC = await this.getConsultasSISEC()
        const { Total, ReasonBlocked, IsPermitted } = consultasSISEC

        if ( intEstatus && Total === 0 ) {

        /*  this.$vs.notify({
            title: 'Ocurrio un error',
            text: 'El bróker no tiene la facultad de realizar consultas.',
            color:'danger',
            position:'top-right'
          })*/

          return false

        } 

        //if ( objContenido > 0 ) return { origen: 'comparador' }

        if ( Total > 0 ) return { origen: 'sisec' }

        // this.$vs.loading.close('#casos > .con-vs-loading')
        
      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }

    },

    async getConsultasSISEC () {
      const self = this

      try {
        
        const TOKEN_SISEC = await this.getTokenSisec()
        const { access_token } = TOKEN_SISEC.data

        const userInfoJson = localStorage.getItem('userInfo')
        const { SocGuid } = JSON.parse(userInfoJson)
  
        if ( !SocGuid ) {
          
          return { Total: 0, ReasonBlocked: 'El bróker no tiene la facultad de realizar consultas.', IsPermitted: false }

        }

        const objRequest = {
          EmployeeSOCId: SocGuid,
          PersonType: 'Fisico'
        }

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado' // PROD

        const resp = await this.$axios.post(URL_API_SISEC, objRequest, { 
          headers : {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        const { status, data } = resp

        if ( status !== 200 ) {
          this.$vs.notify({
            title:'Ocurrio un error en Consultas disponibles',
            text:'Ocurrió un problema, inténtelo nuevamente más tarde.',
            color:'danger',
            position:'top-right'
          })

          return []
        }

        return data

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }
    },

    async getConsultasComparador () {

      try {
        const objRequestCount = {
          strApiKey: this.key,
          strMetodo: 'BrokerConsultasBuro',
          objParametros: {
            ApiKey: this.key
          }
        }

        const { data } = await this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        
        if ( data.intEstatus ) {
          return { intEstatus: data.intEstatus, objContenido: 0, strError: data.strError }
        }

        return { intEstatus: data.intEstatus, objContenido: data.objContenido, strError: null }

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }

    },

    async consultar () {

      try {
        
        const origenConsulta = await this.getOrigenConsultaBuro()

        if ( !origenConsulta ) {

        /*  return self.$vs.notify({
            title:'Ocurrio un error en consulta de reporte de credito',
            text: 'El bróker no tiene la facultad de realizar consultas.',
            color:'danger',
            position:'top-right'
          })*/

        }

        const userInfoJson = localStorage.getItem('userInfo')
        const { SocGuid } = JSON.parse(userInfoJson)
        this.SocGuid = SocGuid

        if ( !this.SocGuid ) {
          /* return self.$vs.notify({
            title:'Ocurrio un error en consulta de reporte de credito',
            text: 'El bróker no tiene la facultad de realizar consultas.',
            color:'danger',
            position:'top-right'
          })*/
        }

        const objRequestConsultaBuro = {
          strApiKey: this.key,
          strMetodo: 'ConsultaBuro',
          objSolicitante: {
            EMail: this.email
          },
          origenConsulta: origenConsulta.origen
        }

        // console.log({ objRequestConsultaBuro })

        const resp = await this.$axios.post('/',objRequestConsultaBuro,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})

        // console.log({ data: resp.data })

        const { intEstatus, objContenido, strError } = resp.data

        this.consultaCirculo = objContenido.objGeneral
        this.creditos = objContenido.vctCreditos
        for (var i = 0; i < this.creditos.length; i++) {
          this.creditos[i].LimiteDeCredito = this.creditos[i].LimiteDeCredito.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.creditos[i].SaldoActual = this.creditos[i].SaldoActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.creditos[i].MontoAPagar = this.creditos[i].MontoAPagar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        this.score = objContenido.objGeneral.strScore

        // this.emailClienteBuro()

        const { isReusedScore, objCirculo, idConsulta, issetBroker } = objContenido

        // if ( origenConsulta.origen === 'sisec' && !issetBroker ) {
        if ( origenConsulta.origen === 'sisec' ) {
          // console.log('Consulta a sisec')
          this.consultarSISEC({ isReusedScore, objCirculo, idConsulta })
        }
      } catch (error) {
        
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

      }

      // let self=this
      
      // .then(
      //   response => {
      //     if(response.data.intEstatus == 0){
      //       this.consultaCirculo=response.data.objContenido.objGeneral
      //       this.creditos=response.data.objContenido.vctCreditos
      //       for (var i = 0; i < this.creditos.length; i++) {
      //         this.creditos[i].LimiteDeCredito = this.creditos[i].LimiteDeCredito.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      //         this.creditos[i].SaldoActual = this.creditos[i].SaldoActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      //         this.creditos[i].MontoAPagar = this.creditos[i].MontoAPagar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      //       }
      //       this.score=response.data.objContenido.objGeneral.strScore

      //       this.emailClienteBuro()

      //       const { isReusedScore, objCirculo, idConsulta } = response.data.objContenido
      //       this.consultarSISEC({ isReusedScore, objCirculo, idConsulta })
      //     }else {
      //       this.$vs.notify({
      //         title:'Ocurrio un error en ConsultaBuro',
      //         text:response.data.strError,
      //         color:'danger',
      //         position:'top-right'
      //       })
      //     }
      //   }
      // ).catch(function (error) {
      //   self.$vs.notify({
      //     title:'Ocurrio un error de sistema',
      //     text:error,
      //     color:'danger',
      //     position:'top-right'
      //   })
      // })
    },
    dameSolicitante(){
      let self=this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.solicitante=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    print(){

     /* var is_chrome = Boolean(window.chrome);
      if (is_chrome) {
        setTimeout(function () {
            window.print();
            window.close();
        }, 200);
      }
      else {
          window.print();
          window.close();
      }*/

      // Se envia el id porque el email ya no es unico
       var WhatsPrint =  window.open('https://cotizador.socasesores.com/admin/formatos/FormatoConsultaCirculoCreditoEmailv2.php?s='+this.solicitante.Id);

    }
  }
}
</script>

<style lang="css" scoped>
.personales{
  margin-left: 3%;
  margin-bottom: 3%;
}
@media print {
    button{
      display: none;
    }
}
</style>
